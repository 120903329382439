/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { BalanceAdapter } from '../core/connectors';
import { BalanceOccAdapter } from './adapters';
import { defaultBalanceOccConfig } from './config/default-balance-occ-config';
import './model/balance-occ-endpoints.model';

@NgModule({
  providers: [
    provideConfig(defaultBalanceOccConfig),
    { provide: BalanceAdapter, useClass: BalanceOccAdapter },
  ],
})
export class BalanceOccModule {}
