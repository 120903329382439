/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { OutletModule } from '@spartacus/storefront';
import { MiraklPriceModule } from 'src/app/spartacus/features/mirakl/shared-components/price/price-component.module';
import { BDGProductSummaryComponent } from './product-summary.component';

@NgModule({
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    MiraklPriceModule,
    RouterLink,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: BDGProductSummaryComponent,
        },
      },
    }),
  ],
  declarations: [BDGProductSummaryComponent],
  exports: [BDGProductSummaryComponent],
})
export class BDGProductSummaryModule {}
