/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { BalanceFacade } from '../../root/facade';
import { BalanceConnector } from '../connectors';
import { BalanceUserToken, PaymentDetailsList } from '../model';

@Injectable()
export class BalanceService implements BalanceFacade {
  constructor(
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartFacade,
    private balanceConnector: BalanceConnector
  ) {}

  getUserToken(): Observable<BalanceUserToken> {
    return this.userIdService
      .getUserId()
      .pipe(
        switchMap((userId: string) =>
          this.balanceConnector.getUserToken(userId)
        )
      );
  }

  creditCardList(): Observable<PaymentDetailsList> {
    return this.userIdService
      .getUserId()
      .pipe(
        switchMap((userId: string) =>
          this.balanceConnector.creditCardList(userId)
        )
      );
  }

  selectPaymentMethod(body: any): Observable<any> {
    return combineLatest([
      this.userIdService.getUserId(),
      this.activeCartService.getActive(),
    ]).pipe(
      take(1),
      switchMap(([userId, cart]) => {
        const cartCode = Object.keys(cart).length ? cart.code : 'current';

        return this.balanceConnector.selectPaymentMethod(
          userId,
          cartCode,
          body
        );
      })
    );
  }

  deleteCreditCard(code: string): Observable<{}> {
    return this.userIdService
      .getUserId()
      .pipe(
        switchMap((userId: string) =>
          this.balanceConnector.deleteCreditCard(userId, code)
        )
      );
  }
}
