/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { OccConfig } from '@spartacus/core';

export const customOccConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        product: {
          details:
            'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),' +
            'priceRange,multidimensional,tags,images(FULL),origin,offersSummary(FULL),documents(FULL),salesUnit,ean',
        },
        productSearch:
          'products/search?fields=products(code,name,summary,configurable,configuratorType,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,' +
          'offersSummary(FULL),origin,noOfVariants,threePInvoice,numberOfReviews,priceRange(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        cart:
          'users/${userId}/carts/${cartId}/marketplacedeliverymodes?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,' +
          'appliedOrderPromotions,deliveryOrderGroups,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),' +
          'totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),totalUnitCount,' +
          'deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue,value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,bdgOrderTaxSummaryList(totalTax,taxCode,priceData),threePInvoiceTotalPriceWithTax(FULL),nonThreePInvoiceTotalPriceWithTax(FULL)',
        orderDetail: 'users/${userId}/consignments/${orderId}?fields=FULL',
        orderHistory: 'users/${userId}/consignments',
        unitLevelOrderHistory: 'orgUsers/${userId}/orgUnits/consignments',
        unitLevelOrderDetail:
          'orgUsers/${userId}/orgUnits/consignments/${orderId}?fields=FULL',
        replenishmentOrderDetails:
          'users/${userId}/replenishmentOrders/${replenishmentOrderCode}?fields=FULL,costCenter(FULL),purchaseOrderNumber,paymentType,user,bdgOrderTaxSummaryList(totalTax,taxCode,priceData(FULL)),threePInvoiceTotalPriceWithTax(FULL),nonThreePInvoiceTotalPriceWithTax(FULL)',
        /* eslint-enable */
      },
    },
  },
};
