/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Observable } from 'rxjs';
import { BalanceUserToken, PaymentDetailsList } from '../model';

export abstract class BalanceAdapter {
  abstract getUserToken(userId: string): Observable<BalanceUserToken>;
  abstract creditCardList(userId: string): Observable<PaymentDetailsList>;
  abstract selectPaymentMethod(
    userId: string,
    cartId: string,
    body: any
  ): Observable<any>;
  abstract deleteCreditCard(userId: string, code: string): Observable<{}>;
}
