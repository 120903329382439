/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  unitOrderTranslationChunksConfig,
  unitOrderTranslations,
} from '@spartacus/organization/unit-order/assets';
import { ORGANIZATION_UNIT_ORDER_FEATURE } from '@spartacus/organization/unit-order/root';
import { BDGUnitOrderRootModule } from './unit-order/root/unit-order-root.module';

@NgModule({
  declarations: [],
  imports: [BDGUnitOrderRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ORGANIZATION_UNIT_ORDER_FEATURE]: {
          module: () =>
            import('./unit-order/unit-order.module').then(
              (m) => m.BDGUnitOrderModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: unitOrderTranslations,
        chunks: unitOrderTranslationChunksConfig,
      },
    }),
  ],
})
export class OrganizationUnitOrderFeatureModule {}
