/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import {
  CmsConfig,
  I18nConfig,
  provideConfig,
  provideDefaultConfigFactory,
} from '@spartacus/core';
import {
  ProductVariantColorSelectorModule,
  ProductVariantSizeSelectorModule,
  ProductVariantStyleSelectorModule,
  ProductVariantsGuard,
} from '@spartacus/product/variants/components';
import {
  PRODUCT_VARIANTS_FEATURE,
  ProductVariantStyleIconsComponent,
  ProductVariantStyleIconsModule,
  ProductVariantsRootModule,
  defaultProductVariantsComponentsConfig,
} from '@spartacus/product/variants/root';
import {
  OutletPosition,
  ProductListOutlets,
  provideOutlet,
} from '@spartacus/storefront';
import {
  bdgProductVariantsTranslationChunksConfig,
  bdgProductVariantsTranslations,
} from './variants/assets/translations/translations';
import { BDGProductVariantsContainerComponent } from './variants/components/product-variants-container/product-variants-container.component';

@NgModule({
  declarations: [],
  imports: [
    ProductVariantsRootModule,
    ProductVariantStyleIconsModule,
    ProductVariantSizeSelectorModule,
    ProductVariantStyleSelectorModule,
    ProductVariantColorSelectorModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [PRODUCT_VARIANTS_FEATURE]: {
          module: () =>
            import('./variants/product-variants.module').then(
              (m) => m.BDGProductVariantsModule
            ),
        },
      },
      cmsComponents: {
        ProductVariantSelectorComponent: {
          component: BDGProductVariantsContainerComponent,
          guards: [ProductVariantsGuard],
        },
      },
    }),
    provideDefaultConfigFactory(defaultProductVariantsComponentsConfig),
    provideOutlet({
      id: ProductListOutlets.ITEM_DETAILS,
      position: OutletPosition.AFTER,
      component: ProductVariantStyleIconsComponent,
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: bdgProductVariantsTranslations,
        chunks: bdgProductVariantsTranslationChunksConfig,
      },
    }),
  ],
})
export class ProductVariantsFeatureModule {}
