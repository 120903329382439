/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {
  CmsConfig,
  RoutingConfig,
  provideConfig,
  provideDefaultConfig,
  provideDefaultConfigFactory,
} from '@spartacus/core';
import {
  ORGANIZATION_ADMINISTRATION_FEATURE,
  defaultBudgetRoutingConfig,
  defaultCostCenterRoutingConfig,
  defaultOrganizationLayoutConfig,
  defaultPermissionRoutingConfig,
  defaultUnitsRoutingConfig,
  defaultUserGroupRoutingConfig,
  defaultUserRoutingConfig,
} from '@spartacus/organization/administration/root';
import { BDGdefaultUnitsRoutingConfig } from './config/default-units-routing.config';
import { VertexConfig } from './config/vertex-config';

export function defaultOrganizationAdministrationComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [ORGANIZATION_ADMINISTRATION_FEATURE]: {
        cmsComponents: [
          'ManageBudgetsListComponent',
          'ManageCostCentersListComponent',
          'ManagePermissionsListComponent',
          'ManageUnitsListComponent',
          'ManageUsersListComponent',
          'ManageUserGroupsListComponent',
        ],
      },
    },
  };

  return config;
}

@NgModule({
  providers: [
    provideDefaultConfig(defaultOrganizationLayoutConfig),

    provideDefaultConfig(<RoutingConfig>{
      routing: { routes: { organization: { paths: ['organization'] } } },
    }),
    provideDefaultConfig(defaultBudgetRoutingConfig),
    provideDefaultConfig(defaultCostCenterRoutingConfig),
    provideDefaultConfig(defaultPermissionRoutingConfig),
    provideDefaultConfig(defaultUnitsRoutingConfig),
    provideDefaultConfig(defaultUserRoutingConfig),
    provideDefaultConfig(defaultUserGroupRoutingConfig),
    // Added new routing config for Unit Billing Address
    provideDefaultConfig(BDGdefaultUnitsRoutingConfig),

    provideDefaultConfigFactory(
      defaultOrganizationAdministrationComponentsConfig
    ),
    provideConfig(<VertexConfig>{
      vertex: {
        javascriptUrl: 'https://ccwizard.vertexsmb.com/wizard/button.js',
      },
    }),
  ],
})
export class BDGAdministrationRootModule {}
