/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { VertexAdapter } from '../../core/connectors/vertex.adapter';
import { VertexTokenData } from '../../core/models/vertex.model';

@Injectable()
export class VertexOccAdapter implements VertexAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  retrieveToken(userId: string): Observable<VertexTokenData> {
    const endpoint = this.occEndpoints.buildUrl('retrieveToken', {
      urlParams: { userId },
    });
    return this.http.get<VertexTokenData>(endpoint, {});
  }
}
