/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  BaseOption,
  Product,
  ProductScope,
  ProductService,
  RoutingService,
  VariantOptionQualifier,
  isNotUndefined,
} from '@spartacus/core';
import { filter, take } from 'rxjs/operators';
import { DynamicQualifier } from '../../product-variants.model';

@Component({
  selector: 'bdg-product-variant-dynamic-mirakl-selector',
  templateUrl: './product-variant-dynamic-mirakl-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BDGProductVariantDynamicMiraklSelectorComponent
  implements OnInit, OnChanges
{
  constructor(
    private productService: ProductService,
    private routingService: RoutingService
  ) {}

  @Input()
  product: Product;

  @Input()
  variants: BaseOption;

  qualifiersList: DynamicQualifier[] = [];
  qualifierValueList = new Map();
  mapedProducts = new Map();

  currentProduct = new Map();

  setSelectedProduct(): void {
    this.variants.selected?.variantOptionQualifiers.forEach((qualifier) => {
      this.currentProduct.set(qualifier.qualifier, qualifier.value);
    });
  }

  prepareVariantData(): void {
    this.setSelectedProduct();

    this.variants.options.forEach((option) => {
      option.variantOptionQualifiers.forEach((qualifier, i) => {
        if (
          !this.qualifiersList.find(
            (qualifiersItem) => qualifiersItem.id === qualifier.qualifier
          )
        ) {
          this.qualifiersList.push({
            id: qualifier.qualifier,
            name: qualifier.name,
          });

          this.qualifierValueList.set(qualifier.qualifier, [
            { value: qualifier.value, sortIndex: qualifier.sortIndex },
          ]);
        }
      });
    });

    this.variants.options.forEach((option) => {
      let productQualifier = '';
      option.variantOptionQualifiers.forEach((qualifier, i) => {
        if (
          !this.qualifierValueList
            .get(qualifier.qualifier)
            .find((storedValue) => storedValue.value === qualifier.value)
        ) {
          this.qualifierValueList.set(qualifier.qualifier, [
            ...this.qualifierValueList.get(qualifier.qualifier),
            { value: qualifier.value, sortIndex: qualifier.sortIndex },
          ]);
        }
        productQualifier = `${productQualifier}_${qualifier.value}~`;
      });
      this.mapedProducts.set(productQualifier, option.code);
    });

    //Sorting variant options according to sortIndex
    this.qualifierValueList.forEach(
      (value: VariantOptionQualifier[], key: string) => {
        const sortedOptions = value
          .slice() // Create a shallow copy of the array to avoid modifying the original array
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((obj) => obj.value);

        this.qualifierValueList.set(key, sortedOptions);
      }
    );
  }

  ngOnInit(): void {
    this.prepareVariantData();
  }

  ngOnChanges(): void {
    this.setSelectedProduct();
  }

  getNewlySelectedProductCode(
    changedQualifierId: string,
    qualifierValue: string
  ): string {
    let productKey = '';
    this.currentProduct.forEach((value: string, key: string) => {
      if (key === changedQualifierId) {
        productKey = `${productKey}_${qualifierValue}~`;
      } else {
        productKey = `${productKey}_${value}~`;
      }
    });
    return this.mapedProducts.get(productKey);
  }

  isSelectable(qualifierId: string, qualifierValue: string): boolean {
    if (this.getNewlySelectedProductCode(qualifierId, qualifierValue)) {
      return true;
    }
    return false;
  }

  changeVariant(qualifierId: string, qualifierValue: string): null {
    let code = this.getNewlySelectedProductCode(qualifierId, qualifierValue);

    if (!code) {
      const productKeys = Array.from(this.mapedProducts.keys());
      for (let i = 0, len = productKeys.length; i < len; i++) {
        if (productKeys[i].includes(`_${qualifierValue}~`)) {
          code = this.mapedProducts.get(productKeys[i]);
          break;
        }
      }
    }

    if (code) {
      this.productService
        .get(code, ProductScope.LIST)
        .pipe(
          // below call might looks redundant but in fact this data is going to be loaded anyways
          // we're just calling it earlier and storing
          filter(isNotUndefined),
          take(1)
        )
        .subscribe((product: Product) => {
          this.routingService.go({
            cxRoute: 'product',
            params: product,
          });
        });
    }
    return null;
  }
}
