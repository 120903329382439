/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccEndpoint } from '@spartacus/core';

export interface BalanceOccEndpoints {
  getUserToken?: string | OccEndpoint;
  creditCardList?: string | OccEndpoint;
  selectPaymentMethod?: string | OccEndpoint;
  deleteCreditCard?: string | OccEndpoint;
}

declare module '@spartacus/core' {
  interface OccEndpoints extends BalanceOccEndpoints {}
}
