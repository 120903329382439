<div class="row">
  <div class="col-12 col-md-4">
    <a
      [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate : { product: product.name })
          : undefined
      "
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-image-container"
    >
      <cx-media
        class="cx-product-image"
        [container]="product.images?.PRIMARY"
        format="product"
        [alt]="product.name || product.summary"
      ></cx-media>
    </a>
  </div>
  <div class="col-12 col-md-8">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
    >
      <h2 [innerHtml]="product.nameHtml"></h2>
    </a>

    <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
      <cx-star-rating
        *ngIf="product.averageRating"
        [rating]="product?.averageRating"
      ></cx-star-rating>
      <span class="count" *ngIf="product?.numberOfReviews"
        >({{ product?.numberOfReviews }})</span
      >
      <div *ngIf="!product.averageRating" class="cx-product-no-review">
        {{ "productDetails.noReviews" | cxTranslate }}
      </div>
      <div
        class="cx-product-price"
        [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
      >
        <ng-container *ngIf="(product.priceRange | keyvalue)?.length">
          <div class="text-uppercase from-label">
            {{ "bdgProductList.from" | cxTranslate }}
          </div>
          {{ product.priceRange.minPrice.formattedValue }}
        </ng-container>
        <mirakl-price-component [product]="product"></mirakl-price-component>
      </div>
      <div
        class="p-1"
        *ngIf="
          checkVolumePrices(
            product?.offersSummary?.bestOffer?.allOfferPricingsJSON
          )
        "
      >
        <strong>{{ "bdgProduct.volumePriceAvailable" | cxTranslate }}</strong>
      </div>
    </ng-template>

    <div class="row">
      <div class="col-12 col-md-7 col-xl-7">
        <p class="cx-product-summary" [innerHtml]="product.summary">
          {{ product.summary }}
        </p>
        <div
          class="product-more-offer"
          *ngIf="product.offersSummary?.secondOfferPrice as offersStartFrom"
        >
          {{ "bdgProductList.moreOffer" | cxTranslate }}
          <strong>{{ offersStartFrom.formattedValue }}</strong>
        </div>
      </div>
      <div class="col-12 col-md-5 col-xl-5">
        <ng-container *ngIf="product.offersSummary?.bestOffer">
          <mirakl-product-seller-info
            [bestOffer]="product.offersSummary?.bestOffer"
          />
        </ng-container>
        <div class="text-center font-weight-bolder text-uppercase" *ngIf="product.threePInvoice">
          {{
            "bdgProduct.threePAvailable"| cxTranslate
          }}
        </div>
        <div class="avilable-variants text-center" *ngIf="product.noOfVariants">
          {{
            "bdgProduct.variantsAvailable"
              | cxTranslate : { qty: product.noOfVariants }
          }}
        </div>
        <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
          <a
            *ngIf="product.noOfVariants"
            [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
            class="btn btn-primary btn-block"
            >{{ "bdgProduct.viewMore" | cxTranslate }}</a
          >

          <ng-container
            cxInnerComponentsHost
            *ngIf="!product.noOfVariants"
          ></ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>
