<div class="variant-selector">
  <div class="variant-section" *ngFor="let qulifier of qualifiersList">
    <div class="qualifier-name">
      {{ qulifier.name }}
    </div>
    <div class="qualifier-values">
      <button
        *ngFor="let qualifierValue of qualifierValueList.get(qulifier.id)"
        class="qualifier-btn {{
          isSelectable(qulifier.id, qualifierValue) ? '' : 'non-'
        }}selectable"
        [disabled]="currentProduct.get(qulifier.id) === qualifierValue"
        (click)="changeVariant(qulifier.id, qualifierValue)"
      >
        {{ qualifierValue }}
      </button>
    </div>
  </div>
</div>
