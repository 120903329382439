/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { BDGPaymentMethodsComponent } from './payment-methods.component';
import { BalanceCoreModule } from 'src/app/spartacus/features/balance/core/balance-core.module';
import { BalanceOccModule } from 'src/app/spartacus/features/balance/occ/balance-occ.module';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    BalanceCoreModule,
    BalanceOccModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        AccountPaymentDetailsComponent: {
          component: BDGPaymentMethodsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  declarations: [BDGPaymentMethodsComponent],
  exports: [BDGPaymentMethodsComponent],
})
export class BDGPaymentMethodsModule {}
