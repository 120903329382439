/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceUserToken, PaymentDetailsList } from '../model';
import { BalanceAdapter } from './balance.adapter';

@Injectable()
export class BalanceConnector {
  constructor(protected adapter: BalanceAdapter) {}

  public getUserToken(userId: string): Observable<BalanceUserToken> {
    return this.adapter.getUserToken(userId);
  }

  public creditCardList(userId: string): Observable<PaymentDetailsList> {
    return this.adapter.creditCardList(userId);
  }

  public selectPaymentMethod(
    userId: string,
    cartId: string,
    body: any
  ): Observable<any> {
    return this.adapter.selectPaymentMethod(userId, cartId, body);
  }

  public deleteCreditCard(userId: string, code: string): Observable<{}> {
    return this.adapter.deleteCreditCard(userId, code);
  }
}
