/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Back to cart',
  },
  checkoutProgress: {
    label: 'Checkout Progress',
    deliveryAddress: 'Shipping Address',
    deliveryMode: 'Delivery and Tax Cert',
    paymentDetails: 'Payment',
    reviewOrder: 'Review',
  },
  checkoutAddress: {
    shippingAddress: 'Shipping Address',
    selectYourDeliveryAddress: 'Select your Delivery Address',
    defaultDeliveryAddress: 'Default Delivery Address',
    addNewAddress: 'Add New Address',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Delivery address selected',
  },
  checkoutMode: {
    deliveryMethod: 'Delivery Method',
    standardDelivery: 'Standard Delivery',
    premiumDelivery: 'Premium Delivery',
    deliveryEntries: 'Items to be Shipped',
  },
  checkoutReview: {
    review: 'Review',
    orderItems: 'Order Items',
    confirmThatRead: 'I am confirming that I have read and agreed with the',
    placeOrder: 'Place Order',
    termsAndConditions: 'Terms & Conditions',
    editDeliveryAddressDetails:
      'Edit delivery address details, opens Delivery Address page',
    editPaymentDetails: 'Edit payment details, opens Payment Details page',
    editPaymentType: 'Edit payment method, opens Method of Payment page',
    editDeliveryMode: 'Edit delivery mode, opens Delivery Mode page',
    orderInProcess: 'Order is in process. Please wait.',
    paymentMethod: 'Method(s) of Payment',
  },
  checkoutOrderConfirmation: {
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Thank you for your order!',
    invoiceHasBeenSentByEmail:
      'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext:
      'Create an account for <{{email}}> for a faster checkout on your next visit.',
  },
};
