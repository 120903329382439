<form *ngIf="productCode" [formGroup]="addToCartForm" (submit)="addToCart()">
  <div class="quantity" *ngIf="showQuantity">
    <label>{{ "addToCart.quantity" | cxTranslate }}</label>
    <div class="cx-counter-stock">
      <bdg-item-counter
        *ngIf="hasStock"
        [min]="minQuantity"
        [max]="maxQuantity"
        [step]="packageQty"
        [control]="addToCartForm.get('quantity')"
        [showPackageQtyWaringMessageOnInit]="true"
        (emitWarningMessage)="checkForWarningMessages($event)"
      ></bdg-item-counter>

      <span class="info">
        <span *ngIf="showInventory$ | async">{{ getInventory() }}</span>
        {{
          hasStock
            ? ("addToCart.inStock" | cxTranslate)
            : ("addToCart.outOfStock" | cxTranslate)
        }}</span
      >
      <div class="error-msg" *ngIf="warningMessage | async as message">
        {{ message }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasStock">
    <ng-template
      [cxOutlet]="CartOutlets.ADD_TO_CART_PICKUP_OPTION"
      [(cxComponentRef)]="pickupOptionCompRef"
    ></ng-template>
  </ng-container>

  <button
    *ngIf="hasStock"
    [ngClass]="
      options?.displayAddToCart
        ? 'btn btn-tertiary'
        : 'btn btn-primary btn-block'
    "
    type="submit"
    [disabled]="quantity <= 0 || quantity > maxQuantity"
  >
    <span
      *ngIf="
        options?.addToCartString === ('addToCart.buyItAgain' | cxTranslate)
      "
      class="repeat-icon"
      ><cx-icon [type]="iconTypes.REPEAT"></cx-icon
    ></span>
    <span
      attr.aria-label="{{
        options?.addToCartString ?? ('addToCart.addToCart' | cxTranslate)
      }}"
      [ngClass]="
        options?.addToCartString === ('addToCart.buyItAgain' | cxTranslate)
          ? 'buyItAgainLink'
          : ''
      "
    >
      {{ options?.addToCartString ?? ("addToCart.addToCart" | cxTranslate) }}
    </span>
  </button>
</form>
