<a
  [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
  class="cx-product-image-container"
  tabindex="-1"
>
  <cx-media
    class="cx-product-image"
    [container]="product.images?.PRIMARY"
    format="product"
    [alt]="product.summary"
  ></cx-media>
</a>
<a
  [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
  class="cx-product-name"
  [innerHTML]="product.nameHtml"
></a>

<ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
  <div class="cx-product-rating">
    <cx-star-rating
      *ngIf="product.averageRating"
      [rating]="product?.averageRating"
    ></cx-star-rating>
    <span class="count" *ngIf="product?.numberOfReviews"
      >({{ product?.numberOfReviews }})</span
    >
    <div *ngIf="!product.averageRating">
      {{ "productDetails.noReviews" | cxTranslate }}
    </div>
  </div>
  <div class="cx-product-price-container">
    <div
      class="cx-product-price"
      [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
    >
      <ng-container *ngIf="(product.priceRange | keyvalue)?.length">
        <div class="text-uppercase from-label">
          {{ "bdgProductList.from" | cxTranslate }}
        </div>
        {{ product.priceRange.minPrice.formattedValue }}
      </ng-container>
      <mirakl-price-component
        [product]="product"
        *ngIf="!(product.priceRange | keyvalue)?.length"
      ></mirakl-price-component>
    </div>
  </div>
  <div
    class="text-center p-1"
    *ngIf="
      checkVolumePrices(product?.offersSummary?.bestOffer?.allOfferPricingsJSON)
    "
  >
    <strong>{{ "bdgProduct.volumePriceAvailable" | cxTranslate }}</strong>
  </div>

  <ng-container *ngIf="product.offersSummary?.bestOffer">
    <mirakl-product-seller-info
      [bestOffer]="product.offersSummary?.bestOffer"
    />
  </ng-container>

  <div
    class="product-more-offer text-center"
    *ngIf="product.offersSummary?.secondOfferPrice as offersStartFrom"
  >
    {{ "bdgProductList.moreOffer" | cxTranslate }}
    <strong>{{ offersStartFrom.formattedValue }}</strong>
  </div>
  <div class="text-center font-weight-bolder text-uppercase" *ngIf="product.threePInvoice">
    {{
        "bdgProduct.threePAvailable"| cxTranslate
      }}
  </div>
  <div class="avilable-variants text-center" *ngIf="product.noOfVariants">
    {{
      "bdgProduct.variantsAvailable"
        | cxTranslate : { qty: product.noOfVariants }
    }}
  </div>

</ng-template>

<ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
  <a
    *ngIf="product.noOfVariants"
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="btn btn-primary btn-block"
    >{{ "bdgProduct.viewMore" | cxTranslate }}</a
  >

  <ng-container
    cxInnerComponentsHost
    *ngIf="!product.noOfVariants"
  ></ng-container>
</ng-template>
