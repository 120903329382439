/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfigFactory } from '@spartacus/core';
import { CONTACT_US } from './feature-name';

export function defaultContactUsComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [CONTACT_US]: {
        cmsComponents: ['BDGContactUsComponent'],
      },
    },
  };
  return config;
}

@NgModule({
  providers: [provideDefaultConfigFactory(defaultContactUsComponentsConfig)],
})
export class BDGContactUsRootModule {}
