/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import { SegmentRefsRootModule } from '@spartacus/segment-refs/root';

@NgModule({
  declarations: [],
  imports: [SegmentRefsRootModule],
})
export class SegmentRefsFeatureModule {}
