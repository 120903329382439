/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { VertexTokenData } from '../../core/models/vertex.model';
import { BDG_ADMINISTRATION_CORE_FEATURE } from './feature-name';

export function vertexFacadeFactory() {
  return facadeFactory({
    facade: VertexFacade,
    feature: BDG_ADMINISTRATION_CORE_FEATURE,
    methods: ['retrieveToken'],
    async: true,
  });
}

@Injectable({
  providedIn: 'root',
  useFactory: vertexFacadeFactory,
})
export abstract class VertexFacade {
  abstract retrieveToken(): Observable<VertexTokenData>;
}
