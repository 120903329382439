<nav [attr.aria-label]="navAriaLabel" (mouseleave)="onMouseLeave()">
  <ng-container *ngIf="screenWidth$ | async as screenWidth">
    <!-- Button to open the menu -->
    <button
      (click)="toggleNavigation()"
      class="nav-button"
      [disabled]="!node"
      *ngIf="screenWidth > 991; else mobileNav"
    >
      <cx-spinner *ngIf="!node"></cx-spinner>
      <cx-icon [type]="iconType.LIST" *ngIf="node"></cx-icon>

      {{ "navigation.shopAllCategories" | cxTranslate }}
    </button>

    <ng-container *ngIf="showNavigation">
      <div class="nav-menu-overlay" (click)="toggleNavigation()"></div>
      <!-- First Level Menu -->
      <div class="nav-menu-wrapper">
        <div class="nav-menu level-1" *ngIf="node.children">
          <div class="nav-level-title">
            {{ "navigation.categories" | cxTranslate }}
          </div>
          <ul>
            <li
              *ngFor="let navNode of node.children"
              [class.active]="navNode.title === selectedCategory?.title"
              [class]="node.styleClasses"
              (click)="selectCategory(navNode)"
            >
              {{ navNode.title }}
              <cx-icon
                [type]="iconType.CARET_RIGHT"
                *ngIf="navNode.children"
              ></cx-icon>
            </li>
          </ul>
        </div>

        <!-- Second Level Menu -->
        <div class="nav-menu level-2" *ngIf="selectedCategory">
          <button class="nav-back" (click)="selectCategory(null)">
            <cx-icon [type]="iconType.CARET_LEFT" />
            {{ "common.back" | cxTranslate }}
          </button>
          <div class="nav-level-title">{{ selectedCategory.title }}</div>
          <ul>
            <li
              *ngFor="let subcategory of selectedCategory.children"
              [class.active]="subcategory.title === selectedSubcategory?.title"
              [class]="subcategory.styleClasses"
              (click)="
                subcategory.children?.length
                  ? selectSubcategory(subcategory)
                  : navigateToCategory(subcategory.url)
              "
            >
              {{ subcategory.title }}
              <cx-icon
                [type]="iconType.CARET_RIGHT"
                *ngIf="subcategory.children"
              ></cx-icon>
            </li>
          </ul>
        </div>

        <!-- Third Level Menu -->
        <div class="nav-menu level-3" *ngIf="selectedSubcategory">
          <button class="nav-back" (click)="selectSubcategory(null)">
            <cx-icon [type]="iconType.CARET_LEFT" />
            {{ "common.back" | cxTranslate }}
          </button>
          <div class="nav-level-title">{{ selectedSubcategory.title }}</div>
          <ul>
            <li
              *ngFor="let item of selectedSubcategory.children"
              [class]="item.styleClasses"
              (click)="navigateToCategory(item.url)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>

        <!-- Button to close Menu -->
        <div>
          <cx-icon
            class="nav-close"
            [type]="iconType.CLOSE"
            (click)="toggleNavigation()"
          />
        </div>
      </div>
    </ng-container>

    <ng-template #mobileNav>
      <cx-spinner *ngIf="!node"></cx-spinner>
      <ul class="standard-nav">
        <li
          *ngIf="flyout && (node?.children?.length ?? 0) > 1"
          class="back is-open"
        >
          <button (click)="back()">
            <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
            {{ "common.back" | cxTranslate }}
          </button>
        </li>

        <ng-container *ngFor="let child of node?.children">
          <ng-container
            *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"
          >
          </ng-container>
        </ng-container>
      </ul>
    </ng-template>
  </ng-container>
</nav>
<!-- we generate links in a recursive manner -->

<ng-template #nav let-node="node" let-depth="depth">
  <!-- //NOSONAR -->
  <li>
    <cx-generic-link
      *ngIf="
        node.url && (!node.children || node.children?.length === 0);
        else heading
      "
      [url]="node.url"
      [target]="node.target"
      [style]="node.styleAttributes"
      [class]="node.styleClasses"
      (click)="closeIfClickedTheSameLink(node)"
      onKeyDown="closeIfClickedTheSameLink(node)"
    >
      {{ node.title }}
    </cx-generic-link>

    <ng-template #heading>
      <ng-container *ngIf="flyout && node.children?.length > 0; else title">
        <cx-generic-link
          *ngIf="node.url"
          [url]="node.url"
          [target]="node.target"
          (mouseenter)="onMouseEnter($event, node)"
          (click)="closeIfClickedTheSameLink(node)"
          [ngClass]="{ 'active-item': activeNode === node }"
          onKeyDown="closeIfClickedTheSameLink(node)"
        >
          {{ node.title }}
        </cx-generic-link>
        <button
          [attr.tabindex]="depth < 1 ? 0 : -1"
          [attr.aria-haspopup]="true"
          [attr.aria-expanded]="false"
          [attr.aria-label]="node.title"
          (click)="toggleOpen($any($event))"
          (mouseenter)="onMouseEnter($event, node)"
          (keydown.space)="toggleOpen($any($event))"
          (keydown.esc)="back()"
          [ngClass]="{ 'active-item': activeNode === node }"
        >
          <ng-container *ngIf="!node.url">
            {{ node.title }}
          </ng-container>
          <cx-icon [type]="iconType.CARET_DOWN"></cx-icon>
        </button>
      </ng-container>
      <ng-template #title>
        <span *ngIf="node.title" [attr.tabindex]="-1">
          {{ node.title }}
        </span>
      </ng-template>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper" *ngIf="node.children && node.children.length > 0">
      <ul
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children.length, node)"
        [style.gridTemplateColumns]="
          getGridTemplateColumns(getColumnCount(node.children.length, node))
        "
      >
        <ng-container *ngFor="let child of node.children">
          <ng-container
            *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"
          >
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </li>
</ng-template>
