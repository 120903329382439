<ng-container *ngIf="components$ | async as components">
  <div class="category-title" *ngIf="components?.title">
      {{components?.title}}
    </div>
  <div class="category-section" *ngIf="categoryData$ | async as categoryData">
    <ng-container *ngFor="let category of categoryData; let i = index">
      <div
        class="category-item"
        [style.width.%]="100 / wrapAfter"
        [style.background]="
          category.image ? getUrl(category?.image?.url) : 'none'
        "
      >
      <cx-media [container]="category?.image" *ngIf="category?.image"></cx-media>
        <div class="category-name">
          <cx-generic-link
            *ngIf="setRouterCategoryLink(category?.code)"
            [url]="setRouterCategoryLink(category?.code)"
            [target]="getTarget(category?.code)"
            >{{ category.name }}</cx-generic-link
          >
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
