/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
  NavigationModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { BDGNavigationUIComponent } from './navigation-ui.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    I18nModule,
    GenericLinkModule,
    NavigationModule,
    SpinnerModule,
  ],
  declarations: [BDGNavigationUIComponent],
  exports: [BDGNavigationUIComponent],
})
export class BDGNavigationModule extends NavigationModule {}
