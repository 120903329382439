import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { CartConfig } from '@spartacus/cart/base/root';
import { defaultB2BCheckoutConfig } from '@spartacus/checkout/b2b/root';
import {
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  SiteContextConfig,
  provideConfig,
} from '@spartacus/core';
import { defaultB2bOccConfig } from '@spartacus/setup';
import { StoreFinderConfig } from '@spartacus/storefinder/core';
import { GOOGLE_MAPS_DEVELOPMENT_KEY_CONFIG } from '@spartacus/storefinder/root';
import {
  defaultCmsContentProviders,
  layoutConfig,
  mediaConfig,
} from '@spartacus/storefront';
import { PersonalizationConfig } from '@spartacus/tracking/personalization/root';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(defaultB2bOccConfig),
    provideConfig(defaultB2BCheckoutConfig),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          //Only for local environment
          //baseUrl: 'https://localhost:9002/',
          //baseUrl:'https://api.cjgd0yqdv1-bluediamo1-d1-public.model-t.cc.commerce.ondemand.com',
          //baseUrl:'https://api.cjgd0yqdv1-bluediamo1-s1-public.model-t.cc.commerce.ondemand.com',
        },
      },
    }),
    provideConfig(<SiteContextConfig>{
      context: {
        urlParameters: ['baseSite', 'language', 'currency'],
        baseSite: ['bdg'],
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en',
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '*',
      },
    }),
    provideConfig(<CartConfig>{
      cart: {
        selectiveCart: {
          enabled: false,
        },
      },
    }),
    provideConfig(<PersonalizationConfig>{
      personalization: {
        enabled: true,
      },
    }),
    provideConfig(<StoreFinderConfig>{
      googleMaps: { apiKey: GOOGLE_MAPS_DEVELOPMENT_KEY_CONFIG },
    }),
  ],
})
export class SpartacusConfigurationModule {}
