/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  PaymentDetails,
  TranslationService,
} from '@spartacus/core';
import { Card, ICON_TYPE } from '@spartacus/storefront';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { PaymentDetailsList } from 'src/app/spartacus/features/balance/core/model';
import { BalanceFacade } from 'src/app/spartacus/features/balance/root';

@Component({
  selector: 'bdg-payment-methods',
  templateUrl: './payment-methods.component.html',
})
export class BDGPaymentMethodsComponent implements OnInit {
  paymentMethods$: Observable<PaymentDetails[]>;
  editCard: string | undefined;
  iconTypes = ICON_TYPE;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translation: TranslationService,
    private balanceService: BalanceFacade,
    private cd: ChangeDetectorRef,
    protected globalMessageService?: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.loading$.next(true);
    this.paymentMethods$ = this.balanceService.creditCardList().pipe(
      map((paymentDetailsList: PaymentDetailsList) => {
        this.loading$.next(false);
        return paymentDetailsList.payments;
      }),
      catchError((error) => {
        this.globalMessageService.add(
          { key: 'balance.paymetMethodsUnavailable' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
        return [];
      })
    );

    this.editCard = undefined;
  }

  getCardContent({
    accountHolderName,
    expiryMonth,
    expiryYear,
    cardNumber,
    cardType,
  }: PaymentDetails): Observable<Card> {
    cardNumber = '************' + cardNumber;
    return combineLatest([
      this.translation.translate('common.delete'),
      this.translation.translate('paymentCard.deleteConfirmation'),
      this.translation.translate('paymentCard.expires', {
        month: expiryMonth,
        year: expiryYear,
      }),
    ]).pipe(
      map(([textDelete, textDeleteConfirmation, textExpires]) => {
        const actions: { name: string; event: string }[] = [];

        actions.push({ name: textDelete, event: 'edit' });
        const card: Card = {
          role: 'region',
          textBold: accountHolderName,
          text: [cardNumber ?? '', textExpires],
          actions,
          deleteMsg: textDeleteConfirmation,
          img: this.getCardIcon(cardType?.code ?? ''),
        };

        return card;
      })
    );
  }

  deletePaymentMethod(paymentMethod: PaymentDetails): void {
    if (paymentMethod.id) {
      this.loading$.next(true);
      this.balanceService
        .deleteCreditCard(paymentMethod.id)
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.paymentMethods$ = this.paymentMethods$.pipe(
              map((paymentMethods) =>
                paymentMethods.filter(
                  (paymentMethodsRes) =>
                    paymentMethodsRes.id !== paymentMethod.id
                )
              )
            );
            this.loading$.next(false);
            this.globalMessageService.add(
              { key: 'paymentCard.deletePaymentSuccess' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.cd.markForCheck();
          },
          (error) => {
            this.loading$.next(false);
            this.globalMessageService.add(
              error.error.errors[0].message,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        );
      this.editCard = undefined;
    }
  }

  setEdit(paymentMethod: PaymentDetails): void {
    this.editCard = paymentMethod.id;
  }

  cancelCard(): void {
    this.editCard = undefined;
  }

  getCardIcon(code: string): string {
    let ccIcon: string;
    if (code === 'visa') {
      ccIcon = this.iconTypes.VISA;
    } else if (code === 'master' || code === 'mastercard_eurocard') {
      ccIcon = this.iconTypes.MASTER_CARD;
    } else if (code === 'diners') {
      ccIcon = this.iconTypes.DINERS_CLUB;
    } else if (code === 'amex') {
      ccIcon = this.iconTypes.AMEX;
    } else {
      ccIcon = this.iconTypes.CREDIT_CARD;
    }

    return ccIcon;
  }
}
