/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { BDGContactUsRootModule } from './root/contact-us-root.module';
import { CONTACT_US } from './root/feature-name';
@NgModule({
  imports: [BDGContactUsRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CONTACT_US]: {
          module: () =>
            import('./contact-us.module').then((m) => m.BDGContactUsModule),
        },
      },
    }),
  ],
})
export class BDGContactUsFeatureModule {}
