/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  balanceTranslationChunksConfig,
  balanceTranslations,
} from './assets/translations/translations';
import { BalanceRootModule } from './root/balance-root.module';
import { BALANCE_FEATURE } from './root/feature-name';

@NgModule({
  imports: [BalanceRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [BALANCE_FEATURE]: {
          module: () => import('./balance.module').then((m) => m.BalanceModule),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: balanceTranslations,
        chunks: balanceTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class BalanceFeatureModule {}
