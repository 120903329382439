<div class="cx-page" *ngIf="model$ | async as model">
  <section
    tabindex="-1"
    class="cx-page-section"
    [attr.aria-label]="'productList.productListResults' | cxTranslate"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12" *ngIf="viewMode$ | async as viewMode">
          <div class="cx-sorting top">
            <div class="row">
              <label
                class="form-group cx-sort-dropdown col-12 col-lg-4 mr-auto"
              >
                <span>{{ "productList.sortBy" | cxTranslate }}</span>
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination?.sort"
                  [ariaLabel]="'productList.sortResults' | cxTranslate"
                  ariaControls="product-results-list"
                  placeholder="{{ 'productList.sortBy' | cxTranslate }}"
                ></cx-sorting>
              </label>
              <div *ngIf="!isInfiniteScroll" class="col-auto">
                <div
                  class="cx-pagination"
                  [attr.aria-label]="
                    'productList.productSearchPagination' | cxTranslate
                  "
                >
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <div class="col-auto ml-auto ml-lg-0">
                <bdg-product-view
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                ></bdg-product-view>
              </div>
            </div>
          </div>
          <div id="product-results-list" class="cx-product-container">
            <!-- Product list when using pagination -->
            <ng-container *ngIf="viewMode === ViewModes.Grid">
              <div class="row">
                <bdg-product-grid-item
                  *ngFor="let product of model?.products"
                  [product]="product"
                  class="col-12 col-sm-6 col-md-4"
                ></bdg-product-grid-item>
              </div>
            </ng-container>

            <ng-container *ngIf="viewMode === ViewModes.List">
              <bdg-product-list-item
                *ngFor="let product of model?.products"
                [product]="product"
                class="cx-product-search-list"
              ></bdg-product-list-item>
            </ng-container>

            <!-- Product list when using infinite scroll -->
          </div>
          <div class="cx-sorting bottom">
            <div class="row">
              <label
                class="form-group cx-sort-dropdown col-12 col-lg-4 mr-auto"
              >
                <span>{{ "productList.sortBy" | cxTranslate }}</span>
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination?.sort"
                  [ariaLabel]="'productList.sortResults' | cxTranslate"
                  ariaControls="product-results-list"
                  placeholder="{{ 'productList.sortBy' | cxTranslate }}"
                ></cx-sorting>
              </label>
              <div
                *ngIf="!isInfiniteScroll"
                class="col-auto"
                [attr.aria-label]="
                  'productList.productSearchPagination' | cxTranslate
                "
              >
                <div class="cx-pagination">
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <div class="col-auto ml-auto ml-lg-0">
                <bdg-product-view
                  (modeChange)="setViewMode($event)"
                  [mode]="viewMode"
                ></bdg-product-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
