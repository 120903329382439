/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Observable } from 'rxjs';
import { VertexTokenData } from '../models/vertex.model';

export abstract class VertexAdapter {
  abstract retrieveToken(userId: string): Observable<VertexTokenData>;
}
