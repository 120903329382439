/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { VertexFacade } from '../../root/facade';
import { VertexConnector } from '../connectors/vertex.connector';
import { VertexTokenData } from '../models/vertex.model';

@Injectable()
export class VertexService implements VertexFacade {
  constructor(
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartFacade,
    private vertexConnector: VertexConnector
  ) {}

  retrieveToken(): Observable<VertexTokenData> {
    return this.userIdService
      .getUserId()
      .pipe(
        switchMap((userId: string) =>
          this.vertexConnector.retrieveToken(userId)
        )
      );
  }
}
