<ng-container *ngIf="components$ | async as components">
  <div class="media-component" *ngIf="components.media; else noMedia">
    <cx-media
      class="image-banner-media"
      [container]="components.media"
    ></cx-media>
    <div
      class="headline-media"
      *ngIf="components.headline"
      [innerHTML]="bypassSecurityTrustHtml(components.headline)"
    ></div>
    <div class="banner-link" *ngIf="components.hasButton === 'false'">
      <cx-generic-link
        *ngIf="routerLink"
        [url]="routerLink"
        [target]="getTarget(components)"
        [style]="
          components.buttonTextColor
            ? 'color: ' + components.buttonTextColor
            : ''
        "
        >{{ components.linkName }}</cx-generic-link
      >
    </div>
    <div
      class="banner-button"
      *ngIf="components.hasButton === 'true'"
      [style.background]="
        components.buttonColor ? components.buttonColor : 'unset'
      "
    >
      <cx-generic-link
        *ngIf="routerLink"
        [url]="routerLink"
        [target]="getTarget(components)"
        [style]="
          components.buttonTextColor
            ? 'color: ' + components.buttonTextColor
            : ''
        "
        >{{ components.linkName }}</cx-generic-link
      >
    </div>
  </div>

  <ng-template #noMedia>
    <div
      class="no-media"
      [style.background]="
        components.backgroundColor ? components.backgroundColor : 'unset'
      "
    >
      <div
        class="headline"
        *ngIf="components.headline"
        [innerHTML]="bypassSecurityTrustHtml(components.headline)"
      ></div>
      <div
        class="banner-button-no-media"
        *ngIf="components.hasButton === 'true'"
        [style.background]="
          components.buttonColor ? components.buttonColor : 'unset'
        "
      >
        <cx-generic-link
          *ngIf="routerLink"
          [url]="routerLink"
          [target]="getTarget(components)"
          [style]="
            components.buttonTextColor
              ? 'color: ' + components.buttonTextColor
              : ''
          "
          >{{ components.linkName }}</cx-generic-link
        >
      </div>
      <div class="banner-link" *ngIf="components.hasButton === 'false'">
        <cx-generic-link
          *ngIf="routerLink"
          [url]="routerLink"
          [target]="getTarget(components)"
          [style]="
            components.buttonTextColor
              ? 'color: ' + components.buttonTextColor
              : ''
          "
          >{{ components.linkName }}</cx-generic-link
        >
      </div>
    </div>
  </ng-template>
</ng-container>
