/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ProductListItemComponent,
  ProductListItemContext,
  ProductListItemContextSource,
} from '@spartacus/storefront';

@Component({
  selector: 'bdg-product-list-item',
  templateUrl: './product-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class BDGProductListItemComponent extends ProductListItemComponent {
  checkVolumePrices(allOfferPricingsJSON: string): boolean {
    if(allOfferPricingsJSON) {
      const allOfferPricings = JSON.parse(allOfferPricingsJSON);
      if (allOfferPricings[0].volume_prices && allOfferPricings[0].volume_prices.length > 1) {
        return true;
      }
    }
    return false;
  }
}
