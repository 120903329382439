/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export enum BDGProductDetailOutlets {
  INTRO = 'PDP.INTRO',
  PRICE = 'PDP.PRICE',
  SHARE = 'PDP.SHARE',
  SUMMARY = 'PDP.SUMMARY',
  VOLUME_PRICES = 'PDP.VOLUME_PRICES',
}
export enum BDGProductListOutlets {
  ITEM_ACTIONS = 'cx-product-list-item.actions',
  ITEM_DETAILS = 'cx-product-list-item.details',
}
