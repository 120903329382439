/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import {
  importExportTranslationChunksConfig,
  importExportTranslations,
} from '@spartacus/cart/import-export/assets';
import {
  CART_IMPORT_EXPORT_FEATURE,
  ImportExportRootModule,
} from '@spartacus/cart/import-export/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { BDGImportExportCoreModule } from './import-export/core/bdg-import-export-core.module';
import { BDGImportExportOccModule } from './import-export/occ/bdg-import-export-occ.module';

@NgModule({
  declarations: [],
  imports: [
    ImportExportRootModule,
    BDGImportExportCoreModule.forRoot(),
    BDGImportExportOccModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CART_IMPORT_EXPORT_FEATURE]: {
          module: () =>
            import('./import-export/import-export.module').then(
              (m) => m.BDGImportExportModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: importExportTranslations,
        chunks: importExportTranslationChunksConfig,
      },
    }),
  ],
})
export class CartImportExportFeatureModule {}
