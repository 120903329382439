/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { BalanceUserToken, PaymentDetailsList } from '../../core/model';
import { BALANCE_FEATURE } from '../feature-name';

export function balanceFacadeFactory() {
  return facadeFactory({
    facade: BalanceFacade,
    feature: BALANCE_FEATURE,
    methods: [
      'getUserToken',
      'creditCardList',
      'selectPaymentMethod',
      'deleteCreditCard',
    ],
    async: true,
  });
}

@Injectable({
  providedIn: 'root',
  useFactory: balanceFacadeFactory,
})
export abstract class BalanceFacade {
  abstract getUserToken(): Observable<BalanceUserToken>;
  abstract creditCardList(): Observable<PaymentDetailsList>;
  abstract selectPaymentMethod(body: any): Observable<any>;
  abstract deleteCreditCard(code: string): Observable<{}>;
}
