/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, MediaModule } from '@spartacus/storefront';
import { BDGCarouselComponent } from './carousel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    MediaModule,
    UrlModule,
    I18nModule,
    GenericLinkModule,
  ],
  declarations: [BDGCarouselComponent],
  exports: [BDGCarouselComponent],
})
export class BDGCarouselModule {}
