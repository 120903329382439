<ng-container *ngIf="product$ | async as product">
  <ng-template
    [cxOutlet]="outlets.PRICE"
    [cxOutletContext]="{ product: product }"
  >
    <div class="upc-ean" *ngIf="product?.ean">
      <strong class="font-weight-bold">{{
        "miraklProduct.eanNumber" | cxTranslate
      }}</strong>
      <p [innerHTML]="product?.ean"></p>
    </div>
    <div class="brand" *ngIf="brand">
      <a [routerLink]="brand.url"
        ><img
          [src]="getUrl(brand.image?.url)"
          alt="{{ brand.name }}"
          *ngIf="brand.image?.url; else brandName"
        />
      </a>
      <ng-template #brandName>
        {{ brand.name }}
      </ng-template>
    </div>
    <div
      [attr.aria-label]="'productSummary.newItemPrice' | cxTranslate"
      class="price"
    >
      <mirakl-price-component [product]="product"></mirakl-price-component>
      <span class="uom" *ngIf="product.salesUnit && product?.offersSummary">{{
        "bdgProduct.per" | cxTranslate : { uom: product.salesUnit }
      }}</span>
    </div>
  </ng-template>

  <ng-template
    [cxOutlet]="outlets.SUMMARY"
    [cxOutletContext]="{ product: product }"
  >
    <p [innerHTML]="product?.summary" class="summary"></p>
  </ng-template>

  <ng-template
    *ngIf="product?.offersSummary?.bestOffer?.threePInvoice"
    [cxOutlet]="outlets.SUMMARY"
    [cxOutletContext]="{ product: product }"
  >
    <p>
      <strong>{{
        "bdgProduct.threePPDPNote"
          | cxTranslate }}</strong>
    </p>
  </ng-template>

  <ng-template
    *ngIf="
      checkVolumePrices(product?.offersSummary?.bestOffer?.allOfferPricingsJSON)
    "
    [cxOutlet]="outlets.VOLUME_PRICES"
    [cxOutletContext]="{ product: product }"
  >
    <div class="volume-prices">
      <div class="volume-discounts">
        <strong
          >{{
            "bdgProduct.volumeDiscounts"
              | cxTranslate : { uom: product.salesUnit }
          }}
        </strong>
      </div>
      <div class="table-discounts">
        <div
          *ngFor="
            let volumePrice of getVolumePrices(
              product?.offersSummary?.bestOffer?.allOfferPricingsJSON
            );
            index as i
          "
          class="volume-prices-values"
        >
          <div class="interval">
            {{ volumePrice?.currentValue
            }}<span
              *ngIf="
                i !==
                getVolumePrices(
                  product?.offersSummary?.bestOffer?.allOfferPricingsJSON
                ).length -
                  1
              "
            >
              - </span
            >{{ volumePrice?.lastValue }}
          </div>
          <div class="discount-price">${{ volumePrice?.price }}</div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- @TODO: Temp. Comment out share link while not in use by CMS - CHECK OOTB -->
</ng-container>
