/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VertexTokenData } from '../models/vertex.model';
import { VertexAdapter } from './vertex.adapter';

@Injectable()
export class VertexConnector {
  constructor(protected adapter: VertexAdapter) {}

  public retrieveToken(userId: string): Observable<VertexTokenData> {
    return this.adapter.retrieveToken(userId);
  }
}
