/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Provider } from '@angular/core';
import { BalanceFacade } from '../../root/facade';
import { BalanceService } from './balance.service';

export const facadeProviders: Provider[] = [
  BalanceService,
  {
    provide: BalanceFacade,
    useExisting: BalanceService,
  },
];
