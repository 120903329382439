/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
} from '@angular/core';
import { SemanticPathService } from '@spartacus/core';
import {
  CarouselComponent,
  CarouselService,
} from '@spartacus/storefront';
import { tap } from 'rxjs/operators';

/**
 * Generic carousel component that can be used to render any carousel items,
 * such as products, images, banners, or any component. Carousel items are
 * rendered in so-called carousel slides, and the previous/next buttons as well as
 * the indicator-buttons can used to navigate the slides.
 *
 * The component uses an array of Observables (`items$`) as an input, to allow
 * for lazy loading of items.
 *
 * The number of items per slide is calculated with the `itemWidth`, which can given
 * in pixels or percentage.
 *
 * To allow for flexible rendering of items, the rendering is delegated to the
 * given `template`. This allows for maximum flexibility.
 */
@Component({
  selector: 'bdg-brand-carousel',
  templateUrl: './carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BDGCarouselComponent extends CarouselComponent {
  routerLink: string | any[] | undefined;

  constructor(
    protected el: ElementRef,
    protected service: CarouselService,
    protected urlService: SemanticPathService
  ) {
    super(el, service);
  }

  ngOnInit() {
    this.size$ = this.service
      .getItemsPerSlide(this.el.nativeElement, this.itemWidth)
      .pipe(tap(() => (this.activeSlide = 0)));
  }

  setRouterBrandLink(data: string): string | any[] | undefined {
    if (data) {
      this.routerLink = this.urlService.transform({
        cxRoute: 'category',
        params: { code: data },
      });
    } else {
      this.routerLink = '#';
    }
    return this.routerLink;
  }

}
