/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Category, Config, ProductScope } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, tap } from 'rxjs';
import {
  MiraklProduct,
  ProductOrigins,
} from 'src/app/spartacus/features/mirakl/core/model';
import { BDGProductDetailOutlets } from './product-outlets.model';

export interface BDGVolumeDiscounts {
  currentValue?: number;
  lastValue?: number;
  price?: number;
}

@Component({
  selector: 'bdg-product-summary',
  templateUrl: './product-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BDGProductSummaryComponent {
  productOrigins: typeof ProductOrigins = ProductOrigins;
  outlets = BDGProductDetailOutlets;

  brand: Category;

  product$: Observable<MiraklProduct | null> = this.currentProductService
    .getProduct([ProductScope.DETAILS, ProductScope.PRICE])
    .pipe(
      tap((product) => {
        this.brand = product?.categories?.find((category) => category.brand);
      })
    );

  constructor(
    protected currentProductService: CurrentProductService,
    protected config: Config
  ) {}

  checkVolumePrices(allOfferPricingsJSON: string): boolean {
    if (allOfferPricingsJSON) {
      const allOfferPricings = JSON.parse(allOfferPricingsJSON);
      if (
        allOfferPricings[0].volume_prices &&
        allOfferPricings[0].volume_prices.length > 1
      ) {
        return true;
      }
    }
    return false;
  }

  getVolumePrices(allOfferPricingsJSON: string): BDGVolumeDiscounts[] {
    const allOfferPricings = JSON.parse(allOfferPricingsJSON);

    const modifiedVolumePrices: BDGVolumeDiscounts[] = [];

    if (
      allOfferPricings[0].volume_prices &&
      allOfferPricings[0].volume_prices.length > 1
    ) {
      for (let i = 1; i < allOfferPricings[0]?.volume_prices.length; i++) {
        const currentValue =
          i < allOfferPricings[0]?.volume_prices.length - 1
            ? allOfferPricings[0]?.volume_prices[i].quantity_threshold
            : allOfferPricings[0]?.volume_prices[i].quantity_threshold + ' +';
        const lastValue =
          i < allOfferPricings[0]?.volume_prices.length - 1
            ? allOfferPricings[0]?.volume_prices[i + 1].quantity_threshold - 1
            : null;
        const price = allOfferPricings[0]?.volume_prices[i]?.price
          ? allOfferPricings[0]?.volume_prices[i]?.price
          : null;

        modifiedVolumePrices.push({
          currentValue: currentValue,
          lastValue: lastValue,
          price: price,
        });
      }
    }

    return modifiedVolumePrices || [];
  }

  getBaseUrl(): string {
    return (
      this.config.backend?.media?.baseUrl ??
      this.config.backend?.occ?.baseUrl ??
      ''
    );
  }

  getUrl(url: string): string {
    return !url || url.startsWith('http') || url.startsWith('//')
      ? url
      : this.getBaseUrl() + url;
  }
}
