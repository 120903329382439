/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  organizationTranslationChunksConfig,
  organizationTranslations,
} from '@spartacus/organization/administration/assets';
import { ORGANIZATION_ADMINISTRATION_FEATURE } from '@spartacus/organization/administration/root';
import { BDGAdministrationCoreModule } from './administration/core/bdg-administration-core.module';
import { BDGAdministrationOccModule } from './administration/occ/bdg-administration-occ.module';
import { BDGAdministrationRootModule } from './administration/root/administration-root.module';

@NgModule({
  declarations: [],
  imports: [
    BDGAdministrationRootModule,
    BDGAdministrationCoreModule.forRoot(),
    BDGAdministrationOccModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ORGANIZATION_ADMINISTRATION_FEATURE]: {
          module: () =>
            import('./administration/administration.module').then(
              (m) => m.BDGAdministrationModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: organizationTranslations,
        chunks: organizationTranslationChunksConfig,
      },
    }),
  ],
})
export class OrganizationAdministrationFeatureModule {}
