<ng-container *ngIf="productDocuments$ | async as documentsList">
  <ng-container *ngIf="documentsList?.documents; else noDocumentsMsg">
    <div class="container">
      <table class="table" aria-label="documents-table">
        <tr>
          <th>
            {{ "bdgProduct.bdgDocumentsTab.documentName" | cxTranslate }}
          </th>
          <th class="d-flex align-items-center justify-content-center">
            {{ "bdgProduct.bdgDocumentsTab.view" | cxTranslate }}
          </th>
        </tr>
        <tr *ngFor="let document of documentsList.documents">
          <td class="text-break"><span class="fas fa-file-pdf"></span>{{ document.name }}</td>
          <td class="d-flex align-items-center justify-content-center">
            <span
              class="fa fa-eye d-flex align-items-center justify-content-center"
              aria-hidden="true"
              (click)="downloadDocument(document.url)"
              onKeyDown="downloadDocument(document.url)">
            </span>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
</ng-container>

<ng-template #noDocumentsMsg>
  <div class="text-center mt-2 mb-2">
    {{ "bdgProduct.bdgDocumentsTab.noDocumentsMsg" | cxTranslate }}
  </div>
</ng-template>
