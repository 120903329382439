/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const balance = {
  balance: {
    addNewPayment: 'Add New Payment',
    errorLoadingBalanceJS:
      'Something went wrong during Balance JS library download',
    paymetMethodsUnavailable:
      'Payment methods are unavailable at this time. Please try again later.',
  },
};
