/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
} from '@angular/core';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import { isNotNullable } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  MiraklProduct,
  ProductOrigins,
} from 'src/app/spartacus/features/mirakl/core/model';

@Component({
  selector: 'bdg-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BDGAddToCartComponent extends AddToCartComponent {
  @Input() product: MiraklProduct;
  @Input() defaultMinQty?: number;
  minQuantity = 1;
  packageQty = 1;

  protected setStockInfo(product: MiraklProduct): void {
    if (product.origin === ProductOrigins.MARKETPLACE) {
      this.hasStock = Boolean(product.offersSummary?.bestOffer?.quantity > 0);

      this.minQuantity =
        product.offersSummary?.bestOffer?.minPurchasableQty ?? this.minQuantity;
      this.maxQuantity =
        product.offersSummary?.bestOffer?.maxPurchasableQty ??
        product.offersSummary?.bestOffer?.quantity;
      this.packageQty =
        product.offersSummary?.bestOffer?.packageQty ?? this.packageQty;

      if (this.packageQty > this.minQuantity) {
        this.minQuantity = this.packageQty;
      }
    } else {
      this.hasStock = Boolean(product.stock?.stockLevelStatus !== 'outOfStock');

      if (this.hasStock && product.stock?.stockLevel) {
        this.maxQuantity = product.stock.stockLevel;
      }
    }
    this.quantity = this.minQuantity;
    this.addToCartForm.controls['quantity'].setValue(this.quantity);

    this.inventoryThreshold = product.stock?.isValueRounded ?? false;

    if (this.productListItemContext) {
      this.showQuantity = false;
    }
  }

  ngOnInit() {
    if (this.product) {
      this.processProduct();
    } else if (this.productCode) {
      this.setDefaultStockInfo();
    } else {
      this.subscribeToProduct();
    }
  }

  processProduct() {
    //Checking if this is Marketplace product if yes instead of productCode saving bestOffer.code into productCode variable
    if (this.product.origin === ProductOrigins.MARKETPLACE) {
      this.productCode = this.product.offersSummary?.bestOffer?.code ?? '';
    } else {
      this.productCode = this.product.code ?? '';
    }

    this.setStockInfo(this.product);
    this.cd.markForCheck();
  }

  setDefaultStockInfo() {
    // force hasStock and quantity for the time being, as we do not have more info:
    this.quantity = this.defaultMinQty ?? 1;
    this.addToCartForm.controls['quantity'].setValue(this.quantity);
    this.hasStock = true;
    this.cd.markForCheck();
  }

  subscribeToProduct() {
    this.subscription = (
      this.productListItemContext
        ? this.productListItemContext.product$
        : this.currentProductService.getProduct()
    )
      .pipe(filter(isNotNullable))
      .subscribe((productRespone) => {
        //Checking if this is Marketplace product if yes instead of productCode saving bestOffer.code into productCode variable
        const product = productRespone as MiraklProduct;

        if (product.origin === ProductOrigins.MARKETPLACE) {
          this.productCode = product.offersSummary?.bestOffer?.code ?? '';
        } else {
          this.productCode = product.code ?? '';
        }

        this.setStockInfo(product);
        this.cd.markForCheck();
      });
  }

  addToCart() {
    const quantity = this.addToCartForm.get('quantity')?.value;
    if (!this.productCode || quantity <= 0) {
      return;
    }

    if (this.pickupOptionCompRef instanceof ComponentRef) {
      this.pickupOptionCompRef.instance.intendedPickupLocation$
        .pipe(take(1))
        .subscribe((intendedPickupLocation: any) => {
          this.pickupStore =
            intendedPickupLocation?.pickupOption === 'pickup'
              ? intendedPickupLocation.name
              : undefined;
        });
    }

    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((cartEntries) => {
        this.activeCartService.addEntry(
          this.productCode,
          quantity,
          this.pickupStore
        );

        // A CartUiEventAddToCart is dispatched.  This event is intended for the UI
        // responsible to provide feedback about what was added to the cart, like
        // the added to cart dialog.
        //
        // Because we call activeCartService.getEntries() before, we can be sure the
        // cart library is loaded already and that the event listener exists.
        this.eventService.dispatch(
          this.createCartUiEventAddToCart(
            this.productCode,
            quantity,
            cartEntries.length,
            this.pickupStore
          )
        );
      });
  }

  warningMessage: Observable<string>;
  checkForWarningMessages(warningMessage: Observable<string>) {
    this.warningMessage = warningMessage;
    this.cd.detectChanges();
  }
}
