<div class="product-seller text-center mb-2">
  {{ "miraklProduct.plp.soldBy" | cxTranslate }}
  <a
    [routerLink]="
      {
        cxRoute: 'shopDetailsPageRoute',
        params: { shopId: bestOffer?.shopId }
      } | cxUrl
    "
    >{{ bestOffer?.shopName }}</a
  >
</div>
