<ng-container *ngIf="!basePrice; else useBasePrice">
  <ng-container
    *ngIf="product?.origin === productOrigins.MARKETPLACE; else operatorPrice"
  >
    <ng-container
      *ngIf="product?.offersSummary as offerSummary; else noMarketplacePrice"
    >
      <ng-container
        *ngIf="
          offerSummary.bestOffer?.price?.value <
            offerSummary.bestOffer?.originPrice?.value;
          else marketplacePrice
        "
      >
        <span class="discount-price">
          {{ offerSummary.bestOffer?.price?.formattedValue }}
          <span class="old-price">
            {{ offerSummary.bestOffer?.originPrice?.formattedValue }}
          </span>
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #useBasePrice>
  <ng-container
  *ngIf="product?.origin === productOrigins.MARKETPLACE; else basePriceValue"
>
  <ng-container
    *ngIf="product?.offersSummary as offerSummary; else noMarketplacePrice"
  >
    <ng-container
      *ngIf="oldPrice.value != basePrice.value;
        else basePriceValue
      "
    >
      <span class="discount-base-price">
        {{ basePrice?.formattedValue }}
        <span class="old-price" *ngIf="oldPrice?.formattedValue">
          {{ oldPrice?.formattedValue }}
        </span>
      </span>
    </ng-container>
  </ng-container>
</ng-container>
</ng-template>

<ng-template #operatorPrice>
  {{ product?.price?.formattedValue }}
</ng-template>

<ng-template #noMarketplacePrice>
  {{ "bdgProduct.noPrice" | cxTranslate }}
</ng-template>

<ng-template #marketplacePrice>
  {{ product?.offersSummary?.bestOffer?.price?.formattedValue }}
</ng-template>

<ng-template #basePriceValue>
  {{ basePrice.formattedValue }}
</ng-template>

