/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductVariantsContainerComponent } from '@spartacus/product/variants/components';
import { VariantType } from '../../product-variants.model';

@Component({
  selector: 'bdg-product-variants-container',
  templateUrl: './product-variants-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BDGProductVariantsContainerComponent extends ProductVariantsContainerComponent {
  bdgVariantType = VariantType;
}
