/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  PageMetaResolver,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  AtMessageModule,
  defaultViewConfig,
  IconModule,
  ItemCounterModule,
  ListNavigationModule,
  MediaModule,
  OutletModule,
  PageComponentModule,
  SpinnerModule,
  StarRatingModule,
  ViewConfig,
} from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BDGAddToCartModule } from 'src/app/spartacus/features/cart/base/components/add-to-cart/add-to-cart.module';
import { MiraklPriceModule } from 'src/app/spartacus/features/mirakl/shared-components/price/price-component.module';
import { MiraklProductSellerInfoModule } from 'src/app/spartacus/features/mirakl/shared-components/product-seller-info/product-seller-info.module';
import { BDGProductListComponent } from './container';
import { BDGProductGridItemComponent } from './product-grid-item/product-grid-item.component';
import { BDGProductListItemComponent } from './product-list-item/product-list-item.component';
import { BDGProductViewComponent } from './product-view/product-view.component';
import { BDGSearchPageMetaResolver } from './services/search-page-meta.resolver';

@NgModule({
  providers: [
    provideConfig(<ViewConfig>defaultViewConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSProductListComponent: {
          component: BDGProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        ProductGridComponent: {
          component: BDGProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        SearchResultsListComponent: {
          component: BDGProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        SearchResultsGridComponent: {
          component: BDGProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
      },
    }),
    {
      provide: PageMetaResolver,
      useExisting: BDGSearchPageMetaResolver,
      multi: true,
    },
  ],
  declarations: [
    BDGProductListComponent,
    BDGProductListItemComponent,
    BDGProductGridItemComponent,
    BDGProductViewComponent,
  ],
  exports: [
    BDGProductListComponent,
    BDGProductListItemComponent,
    BDGProductGridItemComponent,
    BDGProductViewComponent,
  ],
  imports: [
    AtMessageModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    InfiniteScrollModule,
    ItemCounterModule,
    ListNavigationModule,
    MediaModule,
    OutletModule,
    PageComponentModule,
    RouterModule,
    SpinnerModule,
    StarRatingModule,
    UrlModule,
    MiraklProductSellerInfoModule,
    BDGAddToCartModule,
    MiraklPriceModule,
  ],
})
export class BDGProductListModule {}
